import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  padding: 3rem 0;
  color: ${props => props.theme.bodyTextColor};
  font-family: ${props => props.theme.bodyFontFamily};
`

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;
  width: 100%;
`
