import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Container = styled.div`
  z-index: 9;
  position: fixed;
  top: ${props => (props.show ? 0 : "-400px")};
  left: 0;
  right: 0;
  padding: 1rem;
  background: black;
  text-align: center;
  font-size: 1.4rem;
  transition: top 0.5s ease-in-out;
`

const CloseButton = styled.button`
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  background: black;
`
export const Announcement = ({ children }) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    setTimeout(() => setShow(true), 1000)
  }, [])
  return (
    <Container show={show}>
      {children}
      <CloseButton
        aria-label="Close"
        type="button"
        onClick={() => setShow(false)}
      >
        Close
      </CloseButton>
    </Container>
  )
}
