import styled from "styled-components"

export const Button = styled.button`
  display: inline-block;
  padding: 0.8em 1.2em;
  border: 1px solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  background: black;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s linear;
  font-size: 14px;
  &:hover {
    background: white;
    color: black;
  }
`

export const ButtonLink = styled.a`
  display: inline-block;
  padding: 0.8em 1.2em;
  border: 1px solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  background: black;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s linear;
  font-size: 14px;
  &:hover {
    background: white;
    color: #000000;
  }
  &:visited {
    text-decoration: none;
  }
`
