import styled from "styled-components"
import { media } from "styles/styled-media"

export const Display1 = styled.h1`
  font-family: ${props => props.theme.titleFontFamily};
  font-weight: 700;
  font-size: 3em;
  text-transform: uppercase;
  ${media.mobile`
    font-size: 2.4em;
  `}
`

export const Title1 = styled.h1`
  font-family: ${props => props.theme.titleFontFamily};
  font-weight: 400;
  font-size: 2rem;
  color: ${props => props.theme.titleTextColor};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0em;
  margin: 10rem 0;
  line-height: 1.5;
  ${media.mobile`
  font-size: 1.6em;
`}
`

export const Title2 = styled.h2`
  font-family: ${props => props.theme.titleFontFamily};
  font-size: 2.4rem;
  color: ${props => props.theme.titleTextColor};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0em;
  line-height: 0.88;
  margin: 0.6em 0 0.2em;
  padding-bottom: 0.1em;
  ${media.mobile`
    margin: 3rem 0 1rem;
  `}
`

export const Title3 = styled.h3`
  font-family: ${props => props.theme.titleFontFamily};
  color: ${props => props.theme.titleTextColor};
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin: 0.6em 0 0.2em;
  ${media.mobile`
  font-size: 1.6rem;
  text-align: center;
`}
`
export const Paragraph = styled.p`
  font-family: ${props => props.theme.bodyFontFamily};
  font-size: 1rem;
  font-weight: 600;
  margin: 1.168rem 0;

  color: ${props => props.theme.bodyTextColor};
  text-align: justify;
`
export const Emphasis = styled.span`
  color: white;
`
// 0.707em
// 0.5em
// 0.354em
