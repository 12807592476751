import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { media } from "styles/styled-media"
import { base } from "styles/theme"
import { Container } from "components/common"

const GlobalStyle = createGlobalStyle`

html {
  font-size: 18px;
  line-height: 1.2;

  ${media.mobile`
  font-size: 14px;
`}
}
  body {
    padding: 0;
    margin: 0;
    background: black;
  }

  a {
    color: white;
    text-decoration: none;
    transition: all 0.1s linear;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    
    &:hover {
      background: "rgb(220,0,08)";
      text-decoration: none;
      border-bottom: 1px solid rgb(220,0,08);
    }
    &:visited {
      color: white;
      text-decoration: none;
    }
  }

`
const Layout = ({ children }) => (
  <>
    <Helmet>
      <meta name="pinterest" content="nopin" />
    </Helmet>
    <ThemeProvider theme={base}>
      <GlobalStyle />
      <Container style={{ paddingBottom: 0 }}>{children}</Container>
    </ThemeProvider>
  </>
)

export default Layout
