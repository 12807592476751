import React from "react"
import styled from "styled-components"

const EmailSpan = styled.span`
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  line-height: 1.4;
  color: white;
  &:after {
    content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
  }
`

export const Email = () => (
  <EmailSpan href="#" data-name="hello" data-domain="eatbitter" data-tld="co" />
)
