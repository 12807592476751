export const base = {
  bodyColor: "#1a1b1d",
  bodyBackgroundColor: "rgb(255, 0, 0)",
  hRed: "#d00000",
  hRed1: "#810000",
  titleTextColor: "rgb(255,255,255)",
  bodyTextColor: "white",
  secondaryBodyTextColor: "rgb(226, 187, 184)",
  placeholderTextColor: "rgb(160, 150, 140)",
  secondaryColor: "white",
  primaryColor: "#2f95dc",
  actionColor: "rgb(255,0,0)",
  // fonts
  titleFontFamily: "'Archivo', sans-serif",
  bodyFontFamily: "'Archivo', sans-serif",

  // links
  linkColor: "rgb(226, 187, 184)",
  linkHoverColor: "rgb(246, 207, 220)",
}
